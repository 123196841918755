import React, { useState } from 'react'
import Lottie from 'lottie-web';
import endAnimationData from '../mobile-animations/endScr.json';
import { useRecoilValue } from 'recoil';
import { scoreState } from '../game/atom';

export const EndScreenMobile = ({currentSession, totalPoints, handleNextLevel, isLoading}) => {
    const animationContainer = React.createRef();
    const savedScore = useRecoilValue(scoreState);
    const [startBtnVisibility, setStartBtnVisibility] = useState('hide');
    const audioBtn = new Audio("./sfx/nextLevel/Positive Game Win v2.mp3");
    const audioWelcome = new Audio("./sfx/welcome/Slot Game Win v2.mp3")
    let lottieAnim;

    React.useEffect(() => {
        const anim = Lottie.loadAnimation({
          container: animationContainer.current,
          animationData: endAnimationData,
          autoplay: false,
          loop: false
        });
        lottieAnim = anim;
        lottieAnim.stop();
        lottieAnim.addEventListener('loaded_images', function() { lottieAnim.play(); setStartBtnVisibility('show'); document.getElementById('loader-init').style.display = "none";});
        const timer = setTimeout(() => {
            // console.log('This will run after 1 second!');
            // document.getElementById('loader-init').style.display = "none";
            // setStartBtnVisibility('show');
            audioWelcome.play();
          }, 1000);

          setTimeout(() => {
            document.getElementById('loader-init').style.display = "none";
          }, 2500);
          
          return () => {
              clearTimeout(timer);
          }
          
    }, [currentSession]);
    
      React.useEffect(() => {         
        isLoading(false);
      });

    return (
        <div>
            <div className='c-container' ref={animationContainer}>
                <div className={`finalPoints ${startBtnVisibility}`}>{savedScore == 0 ? totalPoints : savedScore}</div>
            </div>
        </div>
    )
}
