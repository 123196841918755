import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { timerState } from '../../game/atom';
import Lottie from 'lottie-web';
import infoAnimationData1 from '../../animations/infoScr1.json';
import fingerRight from '../../animations/finger_left.json';
import fingerLeft from '../../animations/finger_right.json';

export const IntroAnimation = ({updateLevel, isLoading, currentSession, currentLevel}) => {
    

    const audioBtn = new Audio("./sfx/nextLevel/Positive Game Win v2.mp3");
    const animFingerLeft = useRef(null);
    const animFingerRight = useRef(null);
    const animContainerScr1 = useRef(null);
    // const animContainerScr2 = React.createRef();
    const [currentScrState, setCurrentScr] = useState(1);
    const [lottieAnim1, setLottieAnim1] = useState([]);
    let lottieAnim;
    let lottieAnimFingerLeft;
    let lottieAnimFingerRight;
    const directionIndicator = useRef(null);
    const powerIndicator = useRef(null);
    const ballRef = useRef(null);
    const [slotIndex, setSlotIndex] = useState(0);
    const [direction, setDirection] = useState(0.5);
    const [power, setPower] = useState(1.5);
    const timerRef = useRef();
    const [timer, setTimer] = useRecoilState(timerState);

    const handleBtnClick = () => {
        // document.getElementById('loader-init').style.display = "block";

        // if (currentSession.gameState !== null) {
        //     updateLevel(currentSession.gameState.level);
        // }
        setSlotIndex(0);
        if (currentScrState === 4)  { updateLevel(1); }
        setCurrentScr(currentScrState + 1);        
        audioBtn.play();
    }

    const decreaseTimer = useCallback(() => {
        const directInd = directionIndicator.current;
        if (directInd) {
            if (directInd.getAttribute('class') === "small-ball one-end") {
                directInd.removeAttribute('style');
                directInd.style.left = "85%";
                directInd.setAttribute('class', 'small-ball other-end');
            }
            else if (directInd.getAttribute('class') === "small-ball other-end") {
                directInd.setAttribute('class', 'small-ball one-end');
                directInd.style.left = "1%";
                directInd.removeAttribute('style');
            }
            directInd.parentNode.style.width = '23.3%';
            directInd.parentNode.style.height = '8%';
            directInd.style.width = '15%';
            directInd.style.marginTop = '1.25%';
        }
        const powerInd = powerIndicator.current;
        if (powerInd) {
            if (powerInd.getAttribute('class') === "small-ball one-end") {
                powerInd.style.top = "85%";
                powerInd.setAttribute('class', 'small-ball other-end');
            }
            else if (powerInd.getAttribute('class') === "small-ball other-end") {
                powerInd.style.top = "1%";
                powerInd.setAttribute('class', 'small-ball one-end');
            }
            powerInd.parentNode.style.width = '5%';
            powerInd.parentNode.style.height = '31%';
            powerInd.style.width = '67%';
            powerInd.style.marginLeft = '4%';
        }
        // directInd.setAttribute('style', 'width: ' + (window.innerWidth) / (window.innerHeight) * 38 + 'px; height: ' + (window.innerWidth) / (window.innerHeight) * 38 + 'px');
        // powerInd.setAttribute('style', 'width: ' + (window.innerWidth) / (window.innerHeight) * 38 + 'px; height: ' + (window.innerWidth) / (window.innerHeight) * 38 + 'px');
    }, [setTimer]);

    useEffect(() => {
        let animSlotIndex = 0;
        let animationLeftFinger = animFingerLeft.current;
        let animationRightFinger = animFingerRight.current;
        const mainAnim = Lottie.loadAnimation({
            container: animContainerScr1.current,
            animationData: infoAnimationData1,
            autoplay: false,
            loop: false
          }); 

          const leftFingerAnim = Lottie.loadAnimation({
            container: animationLeftFinger,
            animationData: fingerLeft,
            autoplay: true,
            loop: true
          }); 
          const rightFingerAnim = Lottie.loadAnimation({
            container: animationRightFinger,
            animationData: fingerRight,
            autoplay: true,
            loop: true
          }); 

        lottieAnim = mainAnim;
        setLottieAnim1(mainAnim);
        lottieAnim.stop();
        lottieAnim.addEventListener('loaded_images', function() { lottieAnim.play(); document.getElementById('loader-init').style.display = "none";});
        lottieAnim.addEventListener('enterFrame', function(event) { 
            if (event.currentTime > 140 && event.currentTime <= 145 && animSlotIndex === 0) {
                mainAnim.pause();
                setSlotIndex(4);
                animSlotIndex++;
                // console.log(event.currentTime);
            } else if (event.currentTime > 270 && event.currentTime <= 274 && animSlotIndex === 1) {
                mainAnim.pause();
                animationLeftFinger.style.opacity = 1;
                setSlotIndex(1);
                animSlotIndex++;
                // console.log(event.currentTime);
            } else if (event.currentTime > 390 && event.currentTime <= 394 && animSlotIndex === 2) {
                mainAnim.pause();
                animationRightFinger.style.opacity = 1;
                setSlotIndex(2);
                animSlotIndex++;
                // console.log(event.currentTime);
            } else if (event.currentTime > 510 && event.currentTime <= 514 && animSlotIndex === 3) {
                mainAnim.pause();
                setSlotIndex(4);
                animSlotIndex++;
                // console.log(event.currentTime);
            }
          });
          lottieAnimFingerLeft = leftFingerAnim;
          lottieAnimFingerRight = rightFingerAnim;
  
          timerRef.current = setInterval(decreaseTimer, 950);
    }, []);

    const stopHorizontalCircle = () => {
        const style = window.getComputedStyle(directionIndicator.current);
        const left = style.getPropertyValue('left');
        let x2 = 0;

        x2 = parseInt(left.substring(0, 3), 10);
        x2 = x2 / (directionIndicator.current.parentNode.offsetWidth - (directionIndicator.current.offsetWidth / 2));
        setDirection(x2);
        setTimeout(() => {
            setSlotIndex(0);
        }, 1000);
        handleBtnClick();
        // fix the position of the small ball to wherever it is
        directionIndicator.current.setAttribute("class", "small-ball");
        directionIndicator.current.style.left = left;
        animFingerLeft.current.style.opacity = 0;

    }

    const stopVerticalCircle = () => {
        const style = window.getComputedStyle(powerIndicator.current);
        const top = style.getPropertyValue('top');
        let y2 = 0;
        y2 = parseInt(top.substring(0, 3), 10);
        y2 = y2 / (powerIndicator.current.parentNode.offsetHeight - (powerIndicator.current.offsetHeight / 2.4));
        setTimeout(() => {
            setSlotIndex(0);
        }, 1000);

        // fix the position of the small ball to wherever it is
        powerIndicator.current.setAttribute("class", "small-ball");
        powerIndicator.current.style.top = top;

        setPower(y2);
        handleBtnClick();
        animFingerRight.current.style.opacity = 0;

        // setGameStateCheck(true);
        // setControlState({ ...controlState, isRunning: false });
    }

    useEffect(() => {

        if (currentScrState > 1) {
            //setIntroAniamtion(infoAnimationData2);
                      
            // lottieAnim1.goToAndPlay(241);
            // console.log(animContainerScr1.current.children[0]);
            // if (lottieAnim1.currentFrame > 240 && lottieAnim1.currentFrame <= 241) {
                // lottieAnim1.currentFrame = 242;
                lottieAnim1.play();
                // lottieAnim1.gotoAndPlay(242);
            // }
            // console.log(lottieAnim1);
            
            } 
    }, [currentScrState]);
     
    useEffect(() => {
        isLoading(false);
    }, []);

    return (
        <div className="intro-scr-animation-container">
            <div ref={animFingerLeft} className="finger-left" />
            <div ref={animFingerRight} className="finger-right" />
            {currentScrState < 5 && <div className='c-container' ref={animContainerScr1} /> }
            {slotIndex === 1 && <><div className="slider horizontal anim1">
                <div ref={directionIndicator} className="small-ball one-end">
                </div>
            </div></>}
            {slotIndex === 2 && <><div className="slider vertical anim1">
                <div ref={powerIndicator} className="small-ball one-end">
                </div>
            </div></>}
            {slotIndex === 1 &&
                <div className='game_btn horizontal opacity-0' onMouseDown={stopHorizontalCircle}>
                    <img src='./images/game/pogodi_pravac.png' alt='Podesi horizontalno' width="35%" />
                </div>}
            {slotIndex === 2 && <div className='game_btn vertical opacity-0' onMouseDown={stopVerticalCircle}>
                <img src='./images/game/pogodi_jacinu.png' alt='Podesi vertikalno' width="35%" />
            </div>}

            {slotIndex > 3 && <div className='intro continue_btn opacity-0' onMouseDown={handleBtnClick}>
                <img src='./images/btns/NASTAVI.png' />
            </div>}
       </div>
    )
}
