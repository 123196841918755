import  { atom } from 'recoil';

export const controlOptions = atom({
    key: 'controlOptions',
    default: {
        isRunning: false,
        speed: 10,
        sliderSpeed: 0
    },
});

export const itterationState = atom({
    key: 'itterationState',
    default: 0,
})

export const scoreState = atom({
    key: 'scoreState',
    default: 0,
});

export const timerState = atom({
    key: 'timerState',
    default: 60,
});

export const positionTimerState = atom({
    key: 'positionTimerState',
    default: 240,
});
