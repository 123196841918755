import React,{ useState, useEffect } from 'react';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

import { RecoilRoot } from "recoil";
import { WelcomeDesk } from './components/WelcomeDesk';
import { IntroAnimation } from './components/IntroAnimationDesk/IntroAnimation';
import { IntroAnimationMobile } from './components/introMobile/IntroAnimationMobile';
import {EndScreen} from './components/EndScreen';
import {EndScreenMobile} from './components/EndScreenMobile';
import { Login } from './components/Login';
import getAxiosInstance from "./config/http";
import {SessionEnd} from './components/SessionEnd';
import {SessionEndMobile} from './components/SessionEnd_Mobile';
import {ContinueGame} from './components/ContinueGame';
import {ContinueGameMobile} from './components/ContinueGameMobile';
import { WelcomeMob } from './components/WelcomeMob';

// import { FullScreen, useFullScreenHandle } from "react-full-screen";

import Game from './game/Game';
import GameMobile from './game/GameMobile';

import './App.css';

function App() {
  const [totalPoints, setTotalPoints] = useState(0);
  const [totalRemainingTime, setTotaRemainingTime] = useState(60);
  const [currentLevel, setCurrentlevel] = useState(-2); //default -1 - Welcome Scr
  const [currentSession, setCurrentSession] = useState({});
  const [numChestOpened, setNumChestOpened] = useState(0);
  const [isSessionActive, setIsSessionActive] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  
  const orientation = isMobile ? "mobile" : "";
  // const handle = useFullScreenHandle();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const [levelPrizes, setLevelPrizes] = useState([]);

  // Adding login, register and createSession logic based on strapi request.
  const login = () => async (userData) => {
    const http = getAxiosInstance();
    // console.warn(http.request.data);
    try {
      const { data } = await http.post("/auth/local-simple", userData);
      http.defaults.headers.common["Authorization"] = `bearer ${data.jwt}`;
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const register = () => async (userData) => {
    const http = getAxiosInstance();
    try {
      const { data } = await http.post("/auth/local-simple/register", userData);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const createSession = () => async (requestData) => {
    const http = getAxiosInstance();
    try {
      const { data } = await http.post("/game-sessions", requestData);
      setCurrentSession(data);
      // console.log("currentDATA", data, currentLevel)
      if (!data.finished && data.gameState === null) {
        setIsSessionActive(false);
        setCurrentlevel(-1);
      } else if (data.finished === false && data.gameState !== null && data.gameState.remainingTime < 60) {
        setIsSessionActive(true);
        setTotalPoints(data.gameState.totalPoints);
        setTotaRemainingTime(data.gameState.remainingTime);
        setCurrentlevel(-3);
      } else if ((data.finished && data.didWin !== null && data.gameState !== null && data.gameState.remainingTime > 0) || (data.finished && data.didWin !== null && data.gameState === null)) {
        setIsSessionActive(false);
        if (data.gameState === null) {
          setTotalPoints(0);
          setTotaRemainingTime(0);
        } else {
          setTotalPoints(data.gameState.totalPoints);
          setTotaRemainingTime(data.gameState.remainingTime);  
        }
        setCurrentlevel(-4);
      } else if (data.finished && data.didWin !== null && data.gameState !== null && data.gameState.remainingTime <= 0) {
        setIsSessionActive(true);
        setTotalPoints(data.gameState.totalPoints);
        setTotaRemainingTime(data.gameState.remainingTime);
        setCurrentlevel(2);
      } else {
        setCurrentlevel(-1);
      }
      return data;
    } catch (e) {
      throw e;
    }
  };

  // End of adding login, register and createSession.

  // Adding open chest logic based on strapi request.
  const tenisOpen = (params) => async (params) => {
    // console.log(params);
    setIsLoaded(false);
    const http = getAxiosInstance();
    let _levelPrizes = [];
    try {
      const { data } = await http.post(
        `/merkurbet/tenis/open?session=${params.session}`,
        { caughtSymbols: params.caughtSymbols, dots: params.dots, releasedDots: params.releasedDots }
      );
      // updateTotalPoints(data.gameState.points, data.gameState.totalPoints);
      // for (let i=0; i < 3; i++) {
      //   _levelPrizes.push([data.gameState.points, data.gameState.points, data.gameState.points]);
      // }
      // setLevelPrizes(_levelPrizes);
      setIsLoaded(true);
      return data;
    } catch (e) {
      console.log(e);
      setIsLoaded(true);
      // updateLevel(-2)
      // setError(e);
      // throw e;
    }
  };
  // End of adding open chest logic.

    // Adding bonus transfer logic based on strapi request.
    const tenisTimer = (params) => async (params) => {
      const http = getAxiosInstance();
      try {
        const { data } = await http.post(
          `/merkurbet/tenis/timer?session=${params.session}`,
          { caughtSymbols: params.caughtSymbols }
        );
        setCurrentSession(data);
        return data;
      } catch (e) {
        console.log(e);
        return e;
        // setError(e);
        // throw e;
      }
    };
    // End of bonus transfer logic.

  // React.useEffect(() => {
  //   if (currentSession.finished) {
  //     if (currentSession.didWin !== null && currentSession.didWin === false && currentSession.gameState.remainingTime > -2) {
  //       setTotalPoints(currentSession.gameState.totalPoints);
  //       setIsSessionActive(false);
  //       setCurrentlevel(-4);
  //     } else if ((currentSession.didWin === false || currentSession.didWin === true) && currentSession.gameState.remainingTime <= -2) {
  //       console.log("vlegov tuka");
  //       setTotalPoints(currentSession.gameState.totalPoints);
  //       setIsSessionActive(true);
  //       setCurrentlevel(2);
  //     } else {
  //       setIsSessionActive(true);
  //       setTotalPoints(currentSession.gameState.totalPoints);
  //       if (currentSession.gameState.remainingTime <= -2) {
  //         setIsSessionActive(false);
  //         setCurrentlevel(-4);
  //       } else {
  //         setCurrentlevel(-3);
  //       }
  //     }
  //   } else {
  //     setIsSessionActive(true);
  //   }
  // }, [currentSession, isSessionActive])

  //Preload animation images
  useEffect(() => {
    let  levelBgImgs;
    // console.log('inIframe',inIframe());
    if (isMobile) {
      levelBgImgs = [
        './images/mobile/level1/img_4.png',
        './images/mobile/level1/img_3.png',
        './images/mobile/level1/img_2.png',
        './images/mobile/level1/img_1.png',
        './images/mobile/level1/img_0.png',
        './images/flower1click/img_0.png',
        './images/flower1click/img_1.png',
        './images/flower1open/img_0.png',
        './images/flower1open/img_1.png'      ]

    } else {
      levelBgImgs = [
        './images/welcome_desk/img_4.png',
        './images/welcome_desk/img_3.png',
        './images/welcome_desk/img_2.png',
        './images/welcome_desk/img_1.png',
        './images/welcome_desk/img_0.png',
        './images/flower1click/img_0.png',
        './images/flower1click/img_1.png',
        './images/flower1open/img_0.png',
        './images/flower1open/img_1.png'
      ]

    }
    
    cacheImages(levelBgImgs);
    
    //setIsLoading(false);
    return () => {
      //second;
    };
  }, []);
  const cacheImages = async (levelBgImgs) => {
    const promises = await levelBgImgs.map((src) => {
        return new Promise(function (resolve, reject) {
          const img= new Image();
          img.src = src;
          img.onload = resolve();
          img.onerror = reject();
          // console.log("img src: ", src);
        })
    })
    await Promise.all(promises);
    //setIsLoading(false);
  }
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //       //setIsLoading(false);
  //   }, 3000);
  //   return () => clearTimeout(timer);

  // }, []);
  

  const animatePoints = (_points) => {
    let _toPoints = totalPoints + _points;
  }
  // const updateTotalPoints = () => {
  //   let _points = Number(levelPrizes[currentLevel-1][numChestOpened]);
    
  //   setNumChestOpened(numChestOpened+1);
  //   //animatePoints(_points);
  //   setTotalPoints(Number(totalPoints + _points));
  //   console.log("updateTotalPoints:", _points, totalPoints + _points);
  // }

  const updateTotalPoints = (points, totalPoints) => {
    let _points = Number(points);
    
    setNumChestOpened(numChestOpened+1);
    //animatePoints(_points);
    setTotalPoints(Number(totalPoints));
  }
  const updateLevel = (level) => {
    setCurrentlevel(level);
  }
  const loadNextLevel = () => {
    // setIsLoading(true);
    document.getElementById('loader-init').style.display = "block";
    // console.log(currentLevel);
    setCurrentlevel(-2);
    // (currentLevel > 0) ? setCurrentlevel(currentLevel+1) : setCurrentlevel(2);
    // console.log(currentLevel);
  }

  const loadEndScreen = (score) => {
    setIsLoading(true);
    // setTotalPoints(parseInt(score));
    // document.getElementById('loader-init').style.display = "block";
    setCurrentlevel(2);
  }

  const inIframe = () => {
    return true;
    try {
        return window.self !== window.top;
    } catch (e) {
        return e;
    }
  }

    return (
        // <div>
        //   <button onClick={handle.enter}>
        //     Enter fullscreen
        //   </button>
        //   <FullScreen handle={handle}>
            <RecoilRoot key="recoil-root">
              <div className={`App ${orientation}`} key="main-app">
                <header className="App-header">
                </header>
                <main key="main-window">
                  {/* {isLoading && currentLevel < 2 && <div className='loaderIcon'><img src='./images/loader.gif' /></div>} */}
                  <MobileView className='mobile-view' key="browser-view-mobile">
                    {!isSessionActive && inIframe() && currentLevel === -4 && <SessionEndMobile totalPoints={totalPoints} currentSession={currentSession} /> }
                    {currentLevel === -3 && inIframe() && <ContinueGameMobile className='restartScr' totalRemainingTime={totalRemainingTime} totalPoints={totalPoints} updateLevel={updateLevel}  sessionState={currentSession} isLoading={() => {setIsLoading()}} tenisOpen={tenisOpen()} tenisTimer={tenisTimer()} />}
                    {currentLevel === -2 && inIframe() && <Login updateLevel={updateLevel} login={login()} register={register()} createSession={createSession()} />}
                    {currentLevel === -1 && inIframe() && <WelcomeMob isLoading={() => { setIsLoading(); } } updateLevel={updateLevel} />}
                    {currentLevel === 0 && inIframe() && <IntroAnimationMobile isLoading={() => {setIsLoading()}} updateLevel={updateLevel} currentLevel={currentLevel} currentSession={currentSession} /> }
                    {currentLevel === 1 && inIframe() &&  <GameMobile key="game-mobile" isLoading={() => {setIsLoading()}} currentSession={currentSession} currentLevel={currentLevel} handleNextLevel={() => { loadNextLevel()}} endScreen={(e) => { loadEndScreen(e) }} tenisOpen={tenisOpen()} tenisTimer={tenisTimer()} />}
                    {currentLevel > 1 && inIframe() && <EndScreenMobile totalRemainingTime={totalRemainingTime} totalPoints={totalPoints} currentSession={currentSession} handleNextLevel={() => { loadNextLevel(); } } isLoading={() => {setIsLoading()}} tenisOpen={tenisOpen()} tenisTimer={tenisTimer()} />}
                  </MobileView>
                  <BrowserView className='desktop-view' key="browser-view-desktop">
                    {!isSessionActive && inIframe() && currentLevel === -4 && <SessionEnd totalPoints={totalPoints} currentSession={currentSession} /> }
                    {currentLevel === -3 && inIframe() && <ContinueGame className='restartScr' totalRemainingTime={totalRemainingTime} totalPoints={totalPoints} updateLevel={updateLevel}  sessionState={currentSession} isLoading={() => {setIsLoading()}} tenisOpen={tenisOpen()} tenisTimer={tenisTimer()} />}
                    {currentLevel === -2 && inIframe() && <Login updateLevel={updateLevel} login={login()} register={register()} createSession={createSession()} />}
                    {currentLevel === -1 && inIframe() && <WelcomeDesk isLoading={() => { setIsLoading(); } } updateLevel={updateLevel} />}
                    {currentLevel === 0 && inIframe() && <IntroAnimation isLoading={() => {setIsLoading()}} updateLevel={updateLevel} currentLevel={currentLevel} currentSession={currentSession} /> }
                    {currentLevel === 1 && inIframe() &&  <Game key="game-desktop" isLoading={() => {setIsLoading()}} currentSession={currentSession} currentLevel={currentLevel} handleNextLevel={() => { loadNextLevel()}} endScreen={(e) => { loadEndScreen(e) }} tenisOpen={tenisOpen()} tenisTimer={tenisTimer()} />}
                    {currentLevel > 1 && inIframe() && <EndScreen totalRemainingTime={totalRemainingTime} totalPoints={totalPoints} currentSession={currentSession} handleNextLevel={() => { loadNextLevel(); } } isLoading={() => {setIsLoading()}} tenisOpen={tenisOpen()} tenisTimer={tenisTimer()} />}
                  </BrowserView>
                </main>
              </div>
            </RecoilRoot>
        //   </FullScreen>
        // </div>
      );
}

export default App;
